import { createContext, useContext, useEffect, useState } from "react";
import { APP_KEY } from "../../config/constants";

const ConnectorModalContext = createContext();

export function useConnectorModalContext() {
	return useContext(ConnectorModalContext);
}

export default function ConnectorModalProvider({ children }) {
	const [selectedConnector, setSelectedConnector] = useState({});
	const [appImage, setAppImage] = useState("");
	const [hasActiveTask, setHasActiveTask] = useState(false);
	const [activeTaskList, setActiveTaskList] = useState([]);
	const [userInfo, setUserInfo] = useState({});

	//Dev TAB
	const [connectorInfo, setConnectorInfo] = useState({});
	const [apiGeneralInfo, setApiGeneralInfo] = useState(null);
	const [newAuthTypeAdded, setNewAuthTypeAdded] = useState(false);

	useEffect(() => {
		const localStorageData = localStorage.getItem(APP_KEY);
		if (localStorageData) {
			setUserInfo(JSON.parse(localStorageData));
		}
		window.addEventListener("anonymousUserEventChange", () => {
			const localStorageUserData = localStorage.getItem(APP_KEY);
			if (localStorageUserData) {
				setUserInfo(JSON.parse(localStorageUserData));
			}
		});
	}, []);

	return (
		<ConnectorModalContext.Provider
			value={{
				selectedConnector,
				setSelectedConnector,
				appImage,
				setAppImage,
				hasActiveTask,
				setHasActiveTask,
				newAuthTypeAdded,
				setNewAuthTypeAdded,
				userInfo,
				setUserInfo,
				//Dev TAB
				connectorInfo,
				setConnectorInfo,
				apiGeneralInfo,
				setApiGeneralInfo,
				activeTaskList,
				setActiveTaskList,
			}}
		>
			{children}
		</ConnectorModalContext.Provider>
	);
}
