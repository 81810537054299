import { useEffect } from "react";
import { ConnectorAPIService } from "../../../config/api-service";
import { useConnectorModalContext } from "../connectors.context";
import ApiEndPoints from "./ApiEndPoints";
import ApiGeneralInfo from "./ApiGeneralInfo";
import ApiVersion from "./ApiVersion";
import Authentication from "./Authentication";
import ConnectorInfo from "./ConnectorInfo";

const DevTab = () => {
	const {
		selectedConnector,
		setSelectedConnector,
		setConnectorInfo,
		setApiGeneralInfo,
		hasActiveTask,
		setActiveTaskList,
	} = useConnectorModalContext();

	useEffect(() => {
		const getData = async () => {
			try {
				const [
					connectorResponse,
					orchestratorEventResponse,
					connectorInfoResponse,
					apiGeneralInfo,
				] = await Promise.all([
					ConnectorAPIService.getConnectorById(
						selectedConnector.ConnectorID
					),
					ConnectorAPIService.getConnectorOrchestratorEvent(
						selectedConnector.ConnectorID,
						"METADATA"
					),
					ConnectorAPIService.getConnectorInfo(
						selectedConnector.ConnectorID
					),
					ConnectorAPIService.getConnectorApiGeneralInfo(
						selectedConnector.ConnectorID
					),
				]);

				if (connectorResponse) {
					setSelectedConnector(connectorResponse.connector);
				}

				if (orchestratorEventResponse) {
					setActiveTaskList(
						orchestratorEventResponse.dataList[0]?.active_task_list
					);
				}

				if (connectorInfoResponse?.success) {
					setConnectorInfo(connectorInfoResponse.connectorInfo);
				}
				if (apiGeneralInfo?.success) {
					setApiGeneralInfo(apiGeneralInfo.connectorApiGeneralInfo);
				}
			} catch (error) {}
		};
		getData();
		let intervalId;
		if (hasActiveTask) {
			intervalId = setInterval(() => {
				getData();
			}, 10000);
		}
		return () => {
			if (intervalId) {
				clearInterval(intervalId);
			}
		};
	}, [
		hasActiveTask,
		selectedConnector.ConnectorID,
		setSelectedConnector,
		setConnectorInfo,
		setApiGeneralInfo,
		setActiveTaskList,
	]);
	return (
		<>
			<ConnectorInfo />
			<ApiGeneralInfo />
			<ApiVersion />
			<Authentication />
			<ApiEndPoints />
		</>
	);
};

export default DevTab;
