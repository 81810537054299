import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import {
	Box,
	Button,
	Grid,
	IconButton,
	Skeleton,
	TextField,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import React, { Fragment, useEffect, useState } from "react";
import { ConnectorAPIService } from "../../../../config/api-service";
import AlertDialog from "../../../common/dialog";
import { useConnectorModalContext } from "../../connectors.context";

const filter = createFilterOptions();

const AuthTypeAddModal = ({
	showAuthTypeAddModal,
	setShowAuthTypeAddModal,
}) => {
	const [authTypes, setAuthTypes] = useState([]);
	const [isAuthTypesLoading, setIsAuthTypesLoading] = useState(false);
	const [isAddAuthTypeLoading, setIsAddAuthTypeLoading] = useState(false);
	const [isAddNewAuthTypeLoading, setIsNewAddAuthTypeLoading] =
		useState(false);
	const [value, setValue] = useState(null);
	const [open, toggleOpen] = useState(false);
	const [dialogValue, setDialogValue] = useState({
		authentication_type_name: "",
		authentication_type_fields: [{ field_nm: "" }],
	});
	const [authTypeNameError, setAuthTypeNameError] = useState(false);

	const { selectedConnector, setNewAuthTypeAdded } =
		useConnectorModalContext();

	const handleAuthTypeAddModalClose = () => {
		setShowAuthTypeAddModal(false);
	};

	useEffect(() => {
		setIsAuthTypesLoading(true);
		ConnectorAPIService.getAuthTypes()
			.then((res) => {
				setAuthTypes(res.authTypes);
				setIsAuthTypesLoading(false);
			})
			.catch(() => setIsAuthTypesLoading(false));
	}, []);

	const handleAddAuthType = async () => {
		if (!value?.AuthenticationTypeID) {
			return;
		}

		try {
			setIsAddAuthTypeLoading(true);
			const res =
				await ConnectorAPIService.createConnectorAuthenticationType(
					selectedConnector.ConnectorID,
					{
						authentication_type_id: value.AuthenticationTypeID,
					}
				);

			if (res.success) {
				setIsAddAuthTypeLoading(false);
				setShowAuthTypeAddModal(false);
				setNewAuthTypeAdded((previous) => !previous);
			}
		} catch {
			setIsAddAuthTypeLoading(false);
		}
	};

	const handleClose = () => {
		setDialogValue({
			authentication_type_name: "",
			authentication_type_fields: [{ field_nm: "" }],
		});
		toggleOpen(false);
		setAuthTypeNameError(false);
		handleAuthTypeAddModalClose();
	};

	const handleSubmit = async () => {
		let hasError = false;
		const newVal = dialogValue.authentication_type_fields.map((field) => {
			if (field.field_nm === "") {
				hasError = true;
				return { ...field, error: "Field can not be empty" };
			} else return { ...field, error: "" };
		});
		setDialogValue({ ...dialogValue, authentication_type_fields: newVal });

		if (hasError) {
			return;
		}

		try {
			setIsNewAddAuthTypeLoading(true);
			const res =
				await ConnectorAPIService.createConnectorAuthenticationType(
					selectedConnector.ConnectorID,
					dialogValue
				);

			if (res.success) {
				setIsNewAddAuthTypeLoading(false);
				handleClose();
				setNewAuthTypeAdded((previous) => !previous);
			}
		} catch {
			setIsNewAddAuthTypeLoading(false);
		}
	};

	const changeField = (event, index, field) => {
		setDialogValue((prev) => {
			prev[field][index] = { field_nm: event.target.value };
			return { ...prev };
		});
	};

	const handleRemoveField = (index, field) => {
		const newField = { ...dialogValue };
		newField[field].splice(index, 1);
		setDialogValue(newField);
	};

	const handleAddNewField = (field) => {
		setDialogValue((prev) => {
			return {
				...prev,
				[field]: [...prev[field], { field_nm: "" }],
			};
		});
	};

	return (
		<>
			<AlertDialog
				open={showAuthTypeAddModal}
				handleClose={handleAuthTypeAddModalClose}
				actions={
					<Box sx={{ mt: 2, textAlign: "right" }}>
						<LoadingButton
							onClick={handleAddAuthType}
							loading={isAddAuthTypeLoading}
							variant="contained"
						>
							Save
						</LoadingButton>
					</Box>
				}
				title="Add Auth Type"
				centerAligned={false}
				maxWidth={"md"}
			>
				<Box sx={{ height: "300px" }}>
					{isAuthTypesLoading ? (
						<Skeleton
							variant="rounded"
							height={56}
							width="100%"
							sx={{ borderRadius: "5px" }}
						/>
					) : (
						<Autocomplete
							value={value}
							onChange={(event, newValue) => {
								if (typeof newValue === "string") {
									setTimeout(() => {
										toggleOpen(true);
										setDialogValue((previous) => ({
											...previous,
											authentication_type_name: newValue,
										}));
									});
								} else if (newValue && newValue.inputValue) {
									toggleOpen(true);
									setDialogValue((previous) => ({
										...previous,
										authentication_type_name:
											newValue.inputValue,
									}));
								} else {
									setValue(newValue);
								}
							}}
							filterOptions={(options, params) => {
								const filtered = filter(options, params);

								if (params.inputValue !== "") {
									filtered.push({
										inputValue: params.inputValue,
										AuthenticationTypeName: `Add New Custom Auth Type "${params.inputValue}"`,
										isButton: true,
									});
								}

								return filtered;
							}}
							options={authTypes}
							getOptionLabel={(option) => {
								if (typeof option === "string") {
									return option;
								}
								if (option.inputValue) {
									return option.inputValue;
								}
								return option.AuthenticationTypeName;
							}}
							selectOnFocus
							clearOnBlur
							handleHomeEndKeys
							renderOption={(props, option) => {
								const { key, id, ...optionProps } = props;
								return (
									<li
										key={id}
										{...optionProps}
										style={
											option?.isButton && {
												background:
													"var(--data-con-blue)",
												color: "white",
												margin: "0 1rem",
												marginTop: "10px",
												borderRadius: "5px",
												display: "flex",
												justifyContent: "center",
											}
										}
									>
										{option.AuthenticationTypeName}
									</li>
								);
							}}
							freeSolo
							renderInput={(params) => (
								<TextField
									{...params}
									label="Auth Type"
									placeholder="Search..."
								/>
							)}
						/>
					)}
					<AlertDialog
						open={open}
						handleClose={handleClose}
						actions={
							<Box sx={{ mt: 2, textAlign: "right" }}>
								<LoadingButton
									onClick={handleSubmit}
									loading={isAddNewAuthTypeLoading}
									variant="contained"
								>
									Save
								</LoadingButton>
							</Box>
						}
						title="Add Auth Type"
						centerAligned={false}
						maxWidth={"md"}
					>
						<Box sx={{ minHeight: "350px" }}>
							<Box>
								<TextField
									required
									label="Auth Type Name"
									variant="outlined"
									value={dialogValue.authentication_type_name}
									onChange={(e) => {
										if (!e.target.value) {
											setAuthTypeNameError(true);
										} else {
											setAuthTypeNameError(false);
										}
										setDialogValue((previous) => ({
											...previous,
											authentication_type_name:
												e.target.value,
										}));
									}}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										inputProps: {
											sx: {
												padding: 1.4,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",
										padding: 0,
										"& .MuiFormHelperText-root": {
											ml: 0,
										},
									}}
									error={authTypeNameError}
									helperText={
										authTypeNameError &&
										"Auth type name is required"
									}
								/>
							</Box>
							<Grid container spacing={2} sx={{ my: 2 }}>
								<Grid item xs={12} md={6} sx={{ gap: 1 }}>
									<TextField
										label="Description"
										variant="outlined"
										size="small"
										multiline
										rows={3}
										value={
											dialogValue?.authentication_type_desc
										}
										onChange={(e) =>
											setDialogValue((previous) => ({
												...previous,
												authentication_type_desc:
													e.target.value,
											}))
										}
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											sx: {
												padding: 1,
											},
											inputProps: {
												sx: {
													padding: 0,
													height: "100%",
													display: "flex",
													alignItems: "center",
													fontSize: "0.875rem",
												},
											},
										}}
										sx={{
											width: "100%",

											padding: 0,
										}}
									/>
								</Grid>
								<Grid item xs={12} md={6} sx={{ gap: 1 }}>
									<TextField
										label="Schema"
										variant="outlined"
										size="small"
										value={
											dialogValue?.authentication_type_schema
										}
										onChange={(e) =>
											setDialogValue((previous) => ({
												...previous,
												authentication_type_schema:
													e.target.value,
											}))
										}
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											sx: {
												padding: 1,
											},
											inputProps: {
												sx: {
													padding: 0,
													height: "100%",
													display: "flex",
													alignItems: "center",
													fontSize: "0.875rem",
												},
											},
										}}
										sx={{
											width: "100%",

											padding: 0,
										}}
										multiline
										rows={3}
									/>
								</Grid>
								{dialogValue?.authentication_type_fields?.map(
									(field, fieldIndex) => (
										<Fragment key={fieldIndex}>
											<Grid
												item
												xs={11.2}
												sx={{ gap: 1 }}
											>
												<TextField
													label={`Field #${
														fieldIndex + 1
													}`}
													variant="outlined"
													size="small"
													value={field.field_nm}
													onChange={(e) =>
														changeField(
															e,
															fieldIndex,
															"authentication_type_fields"
														)
													}
													InputLabelProps={{
														shrink: true,
													}}
													InputProps={{
														sx: {
															height: 37,
															padding: 1,
														},
														inputProps: {
															sx: {
																padding: 0,
																height: "100%",
																display: "flex",
																alignItems:
																	"center",
																fontSize:
																	"0.875rem",
															},
														},
													}}
													sx={{
														width: "100%",
														padding: 0,
														"& .MuiFormHelperText-root":
															{
																ml: 0,
															},
													}}
													error={field?.error}
													helperText={field?.error}
												/>
											</Grid>
											{dialogValue
												.authentication_type_fields
												.length > 1 && (
												<Grid
													item
													xs={0.8}
													sx={{ textAlign: "right" }}
												>
													<IconButton
														disableRipple
														sx={{ px: 0 }}
														aria-label="delete"
														onClick={() =>
															handleRemoveField(
																fieldIndex,
																"authentication_type_fields"
															)
														}
													>
														<DeleteIcon />
													</IconButton>
												</Grid>
											)}
										</Fragment>
									)
								)}
								<Grid
									item
									xs={12}
									md={6}
									sx={{
										gap: 1,
										textAlign: {
											xs: "center",
											md: "unset",
										},
									}}
								>
									<Button
										variant="contained"
										sx={{
											backgroundColor:
												"var(--data-con-blue)",
										}}
										onClick={() =>
											handleAddNewField(
												"authentication_type_fields"
											)
										}
									>
										ADD Field
									</Button>
								</Grid>
							</Grid>
						</Box>
					</AlertDialog>
				</Box>
			</AlertDialog>
		</>
	);
};

export default AuthTypeAddModal;
