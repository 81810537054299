import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { JiraAPIService } from "../../config/api-service";
import Dialog from "../common/dialog";
import AppTab from "./AppTab";
import ConnectorModalHeaderLoader from "./ConnectorModalHeaderLoader";
import ConnectorModalProvider, {
	useConnectorModalContext,
} from "./connectors.context";
import DevTab from "./DevTab";
import TasksTab from "./TasksTab";

const ConnectorModal = ({
	showConnectorModal,
	handleClose,
	connector,
	title,
	appImage,
	isNewBuild = false,
}) => {
	return (
		<ConnectorModalProvider>
			<Modal
				showConnectorModal={showConnectorModal}
				handleClose={handleClose}
				title={title}
				connector={connector}
				appImage={appImage}
				isNewBuild={isNewBuild}
			/>
		</ConnectorModalProvider>
	);
};

export default ConnectorModal;

function TabPanel(props) {
	const { children, value, index } = props;

	return (
		<div role="tabpanel" hidden={value !== index}>
			{value === index && <Box sx={{ py: 2 }}>{children}</Box>}
		</div>
	);
}

const Modal = ({
	showConnectorModal,
	handleClose,
	connector,
	title,
	appImage,
	isNewBuild,
}) => {
	const [value, setValue] = useState(0);
	const [taskCompleted, setTaskCompleted] = useState("");
	const [tasks, setTasks] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const {
		setSelectedConnector,
		setAppImage,
		setHasActiveTask,
		hasActiveTask,
	} = useConnectorModalContext();

	useEffect(() => {
		setSelectedConnector(connector);
		setAppImage(appImage);
		const getTasks = async (repeated = false) => {
			!repeated && setIsLoading(true);
			try {
				const response = await JiraAPIService.getTasks(
					connector.ConnectorID
				);
				if (response) {
					setTasks(response);
					const totalTask = response.length;
					let completedTask = 0;
					let hasActive = false;

					response.forEach((task) => {
						if (task.status_name === "Completed") {
							completedTask++;
						}
						if (task.status_id === 2) {
							hasActive = true;
						}
					});

					setHasActiveTask(hasActive);
					setTaskCompleted(
						`${completedTask}/${totalTask}(${parseInt(
							(completedTask * 100) / totalTask || 0
						)}%)`
					);

					!isNewBuild
						? setIsLoading(false)
						: isNewBuild && !tasks.length
						? setIsLoading(true)
						: setIsLoading(false);
				}
			} catch (error) {
				setIsLoading(false);
			}
		};

		getTasks();

		let intervalId;

		const pollingInterval = isNewBuild && tasks.length === 0 ? 5000 : 10000;

		if (hasActiveTask || (isNewBuild && tasks.length === 0)) {
			intervalId = setInterval(() => {
				getTasks(true);
			}, pollingInterval);
		}

		return () => {
			if (intervalId) {
				clearInterval(intervalId);
			}
		};
	}, [
		connector,
		appImage,
		setSelectedConnector,
		setAppImage,
		setHasActiveTask,
		hasActiveTask,
		isNewBuild,
		tasks.length,
	]);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleModalClose = () => {
		handleClose();
		setValue(0);
		setSelectedConnector({});
		setAppImage("");
		setHasActiveTask(false);
	};
	return (
		<Dialog
			open={showConnectorModal}
			handleClose={handleModalClose}
			title={
				isNewBuild && isLoading ? (
					<Box
						sx={{ display: { md: "flex" }, alignItems: "baseline" }}
					>
						<>{title}</>
						<Box sx={{ flexGrow: { md: 1 } }}>
							<Typography
								sx={{
									textAlign: "center",
									color: "var(--data-con-blue)",
								}}
							>
								Please hold on for a moment while we build the{" "}
								{title} connector
							</Typography>
						</Box>
					</Box>
				) : (
					title
				)
			}
			centerAligned={false}
			maxWidth={"lg"}
		>
			{isLoading ? (
				<ConnectorModalHeaderLoader />
			) : (
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						flexWrap: "wrap",
						alignItems: "center",
					}}
				>
					<Box sx={{ textAlign: "center" }}>
						<Typography
							variant=""
							sx={{
								fontSize: {
									xs: "30px",
									md: "35px",
									lg: "40px",
								},
							}}
						>
							{taskCompleted}
						</Typography>
						<Typography
							sx={{
								py: 1,
							}}
						>
							Tasks Completed
						</Typography>
					</Box>
					<Box sx={{ textAlign: "center" }}>
						<Typography
							variant=""
							sx={{
								fontSize: {
									xs: "30px",
									md: "35px",
									lg: "40px",
								},
								backgroundColor: "#0000ff33",
								color: "#0000ff",
								borderRadius: "5px",
								p: 1,
							}}
						>
							Active
						</Typography>
						<Typography
							sx={{
								py: 1,
							}}
						>
							Status
						</Typography>
					</Box>
					<Box sx={{ textAlign: "center" }}>
						<Typography
							variant=""
							sx={{
								fontSize: {
									xs: "30px",
									md: "35px",
									lg: "40px",
								},
							}}
						>
							Scout (1/5)
						</Typography>
						<Typography
							sx={{
								py: 1,
							}}
						>
							Phase
						</Typography>
					</Box>
					<Box sx={{ textAlign: "center" }}>
						<Typography
							variant=""
							sx={{
								fontSize: {
									xs: "30px",
									md: "35px",
									lg: "40px",
								},
							}}
						>
							{new Date().toLocaleDateString()}
						</Typography>
						<Typography
							sx={{
								py: 1,
							}}
						>
							Est. Completion
						</Typography>
					</Box>
				</Box>
			)}
			<Box sx={{ width: "100%" }}>
				<Box
					sx={{
						borderBottom: 1,
						borderColor: "divider",
						"&.MuiBox-root": {
							borderBottom: "none",
						},
					}}
				>
					<Tabs
						value={value}
						onChange={handleChange}
						variant="fullWidth"
						// variant="scrollable"
						// scrollButtons
						// allowScrollButtonsMobile
						sx={{
							borderRadius: "5px",
							border: "2px solid var(--data-con-blue)",
							"& .MuiTabs-indicator": {
								display: "none",
							},
							"&.MuiTabs-root": {
								height: "35px",
								minHeight: "unset",
								alignItems: "center",
							},
						}}
					>
						<Tab
							label="TASKS"
							sx={{
								minWidth: "fit-content",
								flex: 1,
								fontWeight: 700,
								fontSize: { md: "20px" },
								color: "var(--data-con-blue)",
								"&.Mui-selected": {
									backgroundColor: "var(--data-con-blue)",
									color: "#fff",
								},
							}}
						/>
						<Tab
							label="DEV"
							sx={{
								minWidth: "fit-content",
								flex: 1,
								fontWeight: 700,
								fontSize: { md: "20px" },
								color: "var(--data-con-blue)",
								borderLeft: "2px solid var(--data-con-blue)",
								borderRight: "2px solid var(--data-con-blue)",
								"&.Mui-selected": {
									backgroundColor: "var(--data-con-blue)",
									color: "#fff",
								},
							}}
						/>
						<Tab
							label="APP"
							sx={{
								minWidth: "fit-content",
								flex: 1,
								fontWeight: 700,
								fontSize: { md: "20px" },
								color: "var(--data-con-blue)",
								"&.Mui-selected": {
									backgroundColor: "var(--data-con-blue)",
									color: "#fff",
								},
							}}
						/>
					</Tabs>
				</Box>
				<TabPanel value={value} index={0}>
					<TasksTab tasks={tasks} isTasksLoading={isLoading} />
				</TabPanel>
				<TabPanel value={value} index={1}>
					<DevTab />
				</TabPanel>
				<TabPanel value={value} index={2}>
					<AppTab />
				</TabPanel>
			</Box>
		</Dialog>
	);
};
