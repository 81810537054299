import { LoadingButton } from "@mui/lab";
import {
	Box,
	Button,
	Divider,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ConnectorAPIService } from "../../../../config/api-service";
import AlertDialog from "../../../common/dialog";
import { useConnectorModalContext } from "../../connectors.context";
import AuthenticationFieldLoader from "./AuthenticationFieldLoader";
import AuthenticationLoader from "./AuthenticationLoader";
import AuthTypeAddModal from "./AuthTypeAddModal";

const changeColor = (defaultInput, userInput) => {
	return userInput !== null && userInput !== defaultInput;
};

const Authentication = () => {
	const [authenticationInfo, setAuthenticationInfo] = useState({});
	const [activeTask, setActiveTask] = useState(null);
	const [authTypes, setAuthTypes] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [authFields, setAuthFields] = useState([]);
	const [isFieldLoading, setIsFieldLoading] = useState(false);
	const [authType, setAuthType] = useState(null);
	const [showFieldAddModal, setShowFieldAddModal] = useState(false);
	const [isAddFieldLoading, setIsAddFieldLoading] = useState(false);
	const [fieldDetails, setFieldDetails] = useState({});
	const [fieldNameError, setFieldNameError] = useState(false);
	const [showAuthTypeAddModal, setShowAuthTypeAddModal] = useState(false);

	const { selectedConnector, activeTaskList, newAuthTypeAdded } =
		useConnectorModalContext();

	useEffect(() => {
		const task = activeTaskList?.find(
			(activeTask) => activeTask.prime_jira_task_id === 7
		);
		setActiveTask(task);
	}, [activeTaskList]);

	useEffect(() => {
		setIsLoading(true);

		ConnectorAPIService.getConnectorAuthenticationTypes(
			selectedConnector.ConnectorID
		)
			.then((res) => {
				setAuthTypes(res.authenticationDetailList);
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
			});
	}, [selectedConnector.ConnectorID, newAuthTypeAdded]);

	const handleAuthTypeChange = async (e) => {
		setIsFieldLoading(true);
		setAuthenticationInfo({});
		setAuthType(e.target.value);
		if (!e.target.value) {
			setAuthFields([]);
			setIsFieldLoading(false);
			return;
		}

		ConnectorAPIService.getConnectorAuthenticationTypeFields(
			selectedConnector.ConnectorID,
			e.target.value
		)
			.then((res) => {
				setAuthFields(res.authenticationTypeFieldList);
				setIsFieldLoading(false);
			})
			.catch((err) => {
				setIsFieldLoading(false);
			});
	};

	const handleFieldAddModalClose = () => {
		setShowFieldAddModal(false);
		setFieldDetails({});
		setFieldNameError(false);
	};

	const handleAddField = async () => {
		if (!fieldDetails?.field_nm) {
			setFieldNameError(true);
			return;
		}

		try {
			setIsAddFieldLoading(true);
			const res =
				await ConnectorAPIService.createConnectorAuthenticationTypeField(
					selectedConnector.ConnectorID,
					authType,
					fieldDetails
				);

			if (res.success) {
				const fields =
					await ConnectorAPIService.getConnectorAuthenticationTypeFields(
						selectedConnector.ConnectorID,
						authType
					);
				if (fields) {
					setAuthFields(fields.authenticationTypeFieldList);
					setIsAddFieldLoading(false);
					setShowFieldAddModal(false);
					setFieldDetails({});
				}
			}
		} catch {
			setIsAddFieldLoading(false);
		}
	};

	const updateAuthenticationField = async () => {
		try {
			await ConnectorAPIService.updateConnectorAuthenticationTypeField(
				selectedConnector.ConnectorID,
				authenticationInfo.authentication_type_field_id,
				{
					field_value_user_input_dsc:
						authenticationInfo.field_value_user_input_dsc,
				}
			);
		} catch {}
	};

	return (
		<>
			<Divider
				sx={{
					textTransform: "uppercase",
					fontSize: { xs: "14px", sm: "17px", md: "19.2px" },
				}}
			>
				AUTHENTICATION
			</Divider>
			{isLoading ? (
				<AuthenticationLoader />
			) : (
				<>
					<Box
						sx={{
							mt: 4,
							display: "flex",
							justifyContent: "center",
							gap: "1rem",
						}}
					>
						<FormControl size="small">
							<InputLabel id="auth_type">Auth Type</InputLabel>
							<Tooltip
								title={
									activeTask
										? `You can not update this field when the task: ${activeTask?.name} is in an active state`
										: ""
								}
								placement="top"
							>
								<Select
									disabled={activeTask}
									labelId="auth_type"
									value={authType}
									onChange={(e) => handleAuthTypeChange(e)}
									label="Auth Type"
									sx={{
										minWidth: 200,
									}}
								>
									<MenuItem value={""}>
										<em>None</em>
									</MenuItem>
									{authTypes.map((authType) => (
										<MenuItem
											key={
												authType.authentication_type_id
											}
											value={
												authType.authentication_type_id
											}
										>
											{authType.authentication_type_name}
										</MenuItem>
									))}
								</Select>
							</Tooltip>
						</FormControl>
						<Button
							variant="contained"
							sx={{
								height: 40,
								backgroundColor: "var(--data-con-blue)",
							}}
							onClick={() => setShowAuthTypeAddModal(true)}
						>
							ADD AUTH TYPE
						</Button>
					</Box>
					<Grid container spacing={2} sx={{ py: 4 }}>
						{isFieldLoading ? (
							<AuthenticationFieldLoader />
						) : (
							authFields.map((authField) => (
								<Grid
									item
									xs={12}
									md={6}
									sx={{ gap: 1 }}
									key={
										authField?.authentication_type_field_id
									}
								>
									<Tooltip
										title={
											activeTask
												? `You can not update this field when the task: ${activeTask?.name} is in an active state`
												: ""
										}
										placement="top"
									>
										<TextField
											disabled={activeTask}
											label={
												authField?.field_nm
													?.charAt(0)
													?.toUpperCase() +
												authField?.field_nm?.slice(1)
											}
											variant="outlined"
											size="small"
											value={
												authField?.field_value_user_input_dsc ||
												authField?.field_value_dsc
											}
											onChange={(e) => {
												setAuthenticationInfo({
													authentication_type_field_id:
														authField.authentication_type_field_id,
													field_value_user_input_dsc:
														e.target.value || null,
												});

												const newAuthFields =
													authFields.map((field) => {
														if (
															field.authentication_type_field_id ===
															authField.authentication_type_field_id
														) {
															field[
																"field_value_user_input_dsc"
															] =
																e.target
																	.value ||
																null;
														}
														return field;
													});

												setAuthFields(newAuthFields);
											}}
											onBlur={updateAuthenticationField}
											InputLabelProps={{
												shrink: true,
												sx: {
													color:
														changeColor(
															authField?.field_value_dsc,
															authField?.field_value_user_input_dsc
														) &&
														"var(--data-con-blue)",
												},
											}}
											InputProps={{
												sx: {
													height: 37,
													padding: 1,
												},
												inputProps: {
													sx: {
														padding: 0,
														height: "100%",
														display: "flex",
														alignItems: "center",
														fontSize: "0.875rem",
													},
												},
											}}
											sx={{
												width: "100%",
												padding: 0,
												".MuiOutlinedInput-notchedOutline":
													changeColor(
														authField?.field_value_dsc,
														authField?.field_value_user_input_dsc
													) && {
														borderColor:
															"var(--data-con-blue)",
													},
											}}
										/>
									</Tooltip>
								</Grid>
							))
						)}
					</Grid>

					{authType && (
						<Box sx={{ textAlign: "center", mb: 4 }}>
							<Button
								variant="contained"
								sx={{
									height: 40,
									minWidth: 150,
									backgroundColor: "var(--data-con-blue)",
								}}
								onClick={() => setShowFieldAddModal(true)}
							>
								ADD FIELD
							</Button>
						</Box>
					)}
					<AlertDialog
						open={showFieldAddModal}
						handleClose={handleFieldAddModalClose}
						actions={
							<Box sx={{ mt: 2, textAlign: "right" }}>
								<LoadingButton
									onClick={handleAddField}
									loading={isAddFieldLoading}
									variant="contained"
								>
									Save
								</LoadingButton>
							</Box>
						}
						title="Add Field"
						centerAligned={false}
						maxWidth={"md"}
					>
						<Grid container spacing={2}>
							<Grid item xs={12} md={6} sx={{ gap: 1 }}>
								<TextField
									required
									label="Field Name"
									variant="outlined"
									value={fieldDetails?.field_nm}
									onChange={(e) => {
										if (!e.target.value) {
											setFieldNameError(true);
										} else {
											setFieldNameError(false);
										}
										setFieldDetails((prev) => ({
											...prev,
											field_nm: e.target.value,
										}));
									}}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										inputProps: {
											sx: {
												padding: 1.4,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",
										padding: 0,
										"& .MuiFormHelperText-root": {
											ml: 0,
										},
									}}
									error={fieldNameError}
									helperText={
										fieldNameError &&
										"Field name is required"
									}
								/>
							</Grid>
							<Grid item xs={12} md={6} sx={{ gap: 1 }}>
								<TextField
									label="Field Value"
									variant="outlined"
									value={fieldDetails?.field_value_dsc}
									onChange={(e) => {
										setFieldDetails((prev) => ({
											...prev,
											field_value_dsc: e.target.value,
										}));
									}}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										inputProps: {
											sx: {
												padding: 1.4,
												height: "100%",
												display: "flex",
												alignItems: "center",
												fontSize: "0.875rem",
											},
										},
									}}
									sx={{
										width: "100%",
										padding: 0,
										"& .MuiFormHelperText-root": {
											ml: 0,
										},
									}}
								/>
							</Grid>
						</Grid>
					</AlertDialog>

					{showAuthTypeAddModal && (
						<AuthTypeAddModal
							showAuthTypeAddModal={showAuthTypeAddModal}
							setShowAuthTypeAddModal={setShowAuthTypeAddModal}
						/>
					)}
				</>
			)}
		</>
	);
};

export default Authentication;
