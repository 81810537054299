import {
	Divider,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ConnectorAPIService } from "../../../../config/api-service";
import { useConnectorModalContext } from "../../connectors.context";
import CredentialsManagementLoader from "./CredentialsManagementLoader";

const changeColor = (defaultInput, userInput) => {
	return userInput !== null && userInput !== defaultInput;
};

const CredentialsManagement = () => {
	const [credentialsManagementInfo, setCredentialsManagementInfo] =
		useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const { selectedConnector, userInfo } = useConnectorModalContext();

	useEffect(() => {
		setIsLoading(true);
		ConnectorAPIService.getConnectorCredentialsManagement(
			selectedConnector.ConnectorID
		)
			.then((response) => {
				setCredentialsManagementInfo(
					response.connectorCredentialsManagement
				);
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);
			});
	}, [selectedConnector.ConnectorID, setCredentialsManagementInfo]);

	const updateConnectorCredentialsManagement = async () => {
		try {
			if (credentialsManagementInfo?.ConnectorCredentialsManagementID) {
				await ConnectorAPIService.updateConnectorCredentialsManagement(
					credentialsManagementInfo.ConnectorCredentialsManagementID,
					{
						...credentialsManagementInfo,
						UpdatedByID: userInfo?.user?.UserID,
					}
				);
			} else {
				const data = {};
				Object.keys(credentialsManagementInfo).forEach((field) => {
					data[field.slice(4)] = credentialsManagementInfo[field];
				});

				const response =
					await ConnectorAPIService.createConnectorCredentialsManagement(
						selectedConnector.ConnectorID,
						data
					);

				if (response?.success) {
					ConnectorAPIService.getConnectorCredentialsManagement(
						selectedConnector.ConnectorID
					)
						.then((response) => {
							setCredentialsManagementInfo(
								response.connectorCredentialsManagement
							);
						})
						.catch((error) => {});
				}
			}
		} catch {}
	};

	return (
		<>
			<Divider
				sx={{
					textTransform: "uppercase",
					fontSize: { xs: "14px", sm: "17px", md: "19.2px" },
				}}
			>
				Credentials Management
			</Divider>

			{isLoading ? (
				<CredentialsManagementLoader />
			) : (
				<Grid container spacing={2} sx={{ py: 4 }}>
					<Grid item xs={12} md={3} sx={{ gap: 1 }}>
						<TextField
							label="Username/Email"
							variant="outlined"
							size="small"
							value={
								credentialsManagementInfo?.UserUsernameEmailTxt ||
								credentialsManagementInfo?.UsernameEmailTxt
							}
							onChange={(e) =>
								setCredentialsManagementInfo((prev) => ({
									...prev,
									UserUsernameEmailTxt: e.target.value,
								}))
							}
							onBlur={updateConnectorCredentialsManagement}
							InputLabelProps={{
								shrink: true,
								sx: {
									color:
										changeColor(
											credentialsManagementInfo?.UsernameEmailTxt,
											credentialsManagementInfo?.UserUsernameEmailTxt
										) && "var(--data-con-blue)",
								},
							}}
							InputProps={{
								sx: {
									height: 37,
									padding: 1,
								},
								inputProps: {
									sx: {
										padding: 0,
										height: "100%",
										display: "flex",
										alignItems: "center",
										fontSize: "0.875rem",
									},
								},
							}}
							sx={{
								width: "100%",
								padding: 0,
								".MuiOutlinedInput-notchedOutline": changeColor(
									credentialsManagementInfo?.UsernameEmailTxt,
									credentialsManagementInfo?.UserUsernameEmailTxt
								) && {
									borderColor: "var(--data-con-blue)",
								},
							}}
						/>
					</Grid>
					<Grid item xs={12} md={3} sx={{ gap: 1 }}>
						<TextField
							label="Password"
							type="password"
							variant="outlined"
							size="small"
							value={
								credentialsManagementInfo?.UserPasswordTxt ||
								credentialsManagementInfo?.PasswordTxt
							}
							onChange={(e) =>
								setCredentialsManagementInfo((prev) => ({
									...prev,
									UserPasswordTxt: e.target.value,
								}))
							}
							onBlur={updateConnectorCredentialsManagement}
							InputLabelProps={{
								shrink: true,
								sx: {
									color:
										changeColor(
											credentialsManagementInfo?.PasswordTxt,
											credentialsManagementInfo?.UserPasswordTxt
										) && "var(--data-con-blue)",
								},
							}}
							InputProps={{
								sx: {
									height: 37,
									padding: 1,
								},
								inputProps: {
									sx: {
										padding: 0,
										height: "100%",
										display: "flex",
										alignItems: "center",
										fontSize: "0.875rem",
									},
								},
							}}
							sx={{
								width: "100%",
								padding: 0,
								".MuiOutlinedInput-notchedOutline": changeColor(
									credentialsManagementInfo?.PasswordTxt,
									credentialsManagementInfo?.UserPasswordTxt
								) && {
									borderColor: "var(--data-con-blue)",
								},
							}}
						/>
					</Grid>
					<Grid item xs={12} md={3} sx={{ gap: 1 }}>
						<FormControl
							size="small"
							sx={{
								minWidth: "100%",
							}}
						>
							<InputLabel
								id="verification_type"
								sx={{
									fontSize: "0.875rem",
									textAlign: "left",
									color:
										changeColor(
											credentialsManagementInfo?.AccountVerificationType,
											credentialsManagementInfo?.UserAccountVerificationType
										) && "var(--data-con-blue)",
								}}
							>
								Account Verification Type
							</InputLabel>
							<Select
								labelId="verification_type"
								value={
									credentialsManagementInfo?.UserAccountVerificationType ||
									credentialsManagementInfo?.AccountVerificationType ||
									""
								}
								onChange={async (e) => {
									setCredentialsManagementInfo((prev) => ({
										...prev,
										UserAccountVerificationType:
											e.target.value,
									}));
									try {
										if (
											credentialsManagementInfo?.ConnectorCredentialsManagementID
										) {
											await ConnectorAPIService.updateConnectorCredentialsManagement(
												credentialsManagementInfo.ConnectorCredentialsManagementID,
												{
													...credentialsManagementInfo,
													UserAccountVerificationType:
														e.target.value,
													UpdatedByID:
														userInfo?.user?.UserID,
												}
											);
										} else {
											const response =
												await ConnectorAPIService.createConnectorCredentialsManagement(
													selectedConnector.ConnectorID,
													{
														AccountVerificationType:
															e.target.value,
													}
												);

											if (response?.success) {
												ConnectorAPIService.getConnectorCredentialsManagement(
													selectedConnector.ConnectorID
												)
													.then((response) => {
														setCredentialsManagementInfo(
															response.connectorCredentialsManagement
														);
													})
													.catch((error) => {});
											}
										}
									} catch {}
								}}
								label="Account Verification Type"
								sx={{
									fontSize: "0.875rem",
									textAlign: "left",
									".MuiOutlinedInput-notchedOutline":
										changeColor(
											credentialsManagementInfo?.AccountVerificationType,
											credentialsManagementInfo?.UserAccountVerificationType
										) && {
											borderColor: "var(--data-con-blue)",
										},
								}}
							>
								<MenuItem value={""}>
									<em>None</em>
								</MenuItem>
								<MenuItem value={"user-pass"}>
									Username - Password
								</MenuItem>
								<MenuItem value={"token"}>Token</MenuItem>
								<MenuItem value={"oauth"}>OAuth</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} md={3} sx={{ gap: 1 }}>
						<TextField
							label="Account Verification"
							variant="outlined"
							size="small"
							value={
								credentialsManagementInfo?.UserAccountVerificationTxt ||
								credentialsManagementInfo?.AccountVerificationTxt
							}
							onChange={(e) =>
								setCredentialsManagementInfo((prev) => ({
									...prev,
									UserAccountVerificationTxt: e.target.value,
								}))
							}
							onBlur={updateConnectorCredentialsManagement}
							InputLabelProps={{
								shrink: true,
								sx: {
									color:
										changeColor(
											credentialsManagementInfo?.AccountVerificationTxt,
											credentialsManagementInfo?.UserAccountVerificationTxt
										) && "var(--data-con-blue)",
								},
							}}
							InputProps={{
								sx: {
									height: 37,
									padding: 1,
								},
								inputProps: {
									sx: {
										padding: 0,
										height: "100%",
										display: "flex",
										alignItems: "center",
										fontSize: "0.875rem",
									},
								},
							}}
							sx={{
								width: "100%",
								padding: 0,
								".MuiOutlinedInput-notchedOutline": changeColor(
									credentialsManagementInfo?.AccountVerificationTxt,
									credentialsManagementInfo?.UserAccountVerificationTxt
								) && {
									borderColor: "var(--data-con-blue)",
								},
							}}
						/>
					</Grid>
					<Grid item xs={12} md={3} sx={{ gap: 1 }}>
						<TextField
							label="Client ID"
							variant="outlined"
							size="small"
							value={
								credentialsManagementInfo?.UserClientIdTxt ||
								credentialsManagementInfo?.ClientIdTxt
							}
							onChange={(e) =>
								setCredentialsManagementInfo((prev) => ({
									...prev,
									UserClientIdTxt: e.target.value,
								}))
							}
							onBlur={updateConnectorCredentialsManagement}
							InputLabelProps={{
								shrink: true,
								sx: {
									color:
										changeColor(
											credentialsManagementInfo?.ClientIdTxt,
											credentialsManagementInfo?.UserClientIdTxt
										) && "var(--data-con-blue)",
								},
							}}
							InputProps={{
								sx: {
									height: 37,
									padding: 1,
								},
								inputProps: {
									sx: {
										padding: 0,
										height: "100%",
										display: "flex",
										alignItems: "center",
										fontSize: "0.875rem",
									},
								},
							}}
							sx={{
								width: "100%",
								padding: 0,
								".MuiOutlinedInput-notchedOutline": changeColor(
									credentialsManagementInfo?.ClientIdTxt,
									credentialsManagementInfo?.UserClientIdTxt
								) && {
									borderColor: "var(--data-con-blue)",
								},
							}}
						/>
					</Grid>
					<Grid item xs={12} md={3} sx={{ gap: 1 }}>
						<TextField
							label="Client Secret"
							variant="outlined"
							size="small"
							value={
								credentialsManagementInfo?.UserClientSecretTxt ||
								credentialsManagementInfo?.ClientSecretTxt
							}
							onChange={(e) =>
								setCredentialsManagementInfo((prev) => ({
									...prev,
									UserClientSecretTxt: e.target.value,
								}))
							}
							onBlur={updateConnectorCredentialsManagement}
							InputLabelProps={{
								shrink: true,
								sx: {
									color:
										changeColor(
											credentialsManagementInfo?.ClientSecretTxt,
											credentialsManagementInfo?.UserClientSecretTxt
										) && "var(--data-con-blue)",
								},
							}}
							InputProps={{
								sx: {
									height: 37,
									padding: 1,
								},
								inputProps: {
									sx: {
										padding: 0,
										height: "100%",
										display: "flex",
										alignItems: "center",
										fontSize: "0.875rem",
									},
								},
							}}
							sx={{
								width: "100%",
								padding: 0,
								".MuiOutlinedInput-notchedOutline": changeColor(
									credentialsManagementInfo?.ClientSecretTxt,
									credentialsManagementInfo?.UserClientSecretTxt
								) && {
									borderColor: "var(--data-con-blue)",
								},
							}}
						/>
					</Grid>
					<Grid item xs={12} md={3} sx={{ gap: 1 }}>
						<TextField
							label="Access Token"
							variant="outlined"
							size="small"
							value={
								credentialsManagementInfo?.UserAccessTokenTxt ||
								credentialsManagementInfo?.AccessTokenTxt
							}
							onChange={(e) =>
								setCredentialsManagementInfo((prev) => ({
									...prev,
									UserAccessTokenTxt: e.target.value,
								}))
							}
							onBlur={updateConnectorCredentialsManagement}
							InputLabelProps={{
								shrink: true,
								sx: {
									color:
										changeColor(
											credentialsManagementInfo?.AccessTokenTxt,
											credentialsManagementInfo?.UserAccessTokenTxt
										) && "var(--data-con-blue)",
								},
							}}
							InputProps={{
								sx: {
									height: 37,
									padding: 1,
								},
								inputProps: {
									sx: {
										padding: 0,
										height: "100%",
										display: "flex",
										alignItems: "center",
										fontSize: "0.875rem",
									},
								},
							}}
							sx={{
								width: "100%",
								padding: 0,
								".MuiOutlinedInput-notchedOutline": changeColor(
									credentialsManagementInfo?.AccessTokenTxt,
									credentialsManagementInfo?.UserAccessTokenTxt
								) && {
									borderColor: "var(--data-con-blue)",
								},
							}}
						/>
					</Grid>
					<Grid item xs={12} md={3} sx={{ gap: 1 }}>
						<TextField
							label="Access Token Expiration"
							variant="outlined"
							size="small"
							value={
								credentialsManagementInfo?.UserAccessTokenExpirationTxt ||
								credentialsManagementInfo?.AccessTokenExpirationTxt
							}
							onChange={(e) =>
								setCredentialsManagementInfo((prev) => ({
									...prev,
									UserAccessTokenExpirationTxt:
										e.target.value,
								}))
							}
							onBlur={updateConnectorCredentialsManagement}
							InputLabelProps={{
								shrink: true,
								sx: {
									color:
										changeColor(
											credentialsManagementInfo?.AccessTokenExpirationTxt,
											credentialsManagementInfo?.UserAccessTokenExpirationTxt
										) && "var(--data-con-blue)",
								},
							}}
							InputProps={{
								sx: {
									height: 37,
									padding: 1,
								},
								inputProps: {
									sx: {
										padding: 0,
										height: "100%",
										display: "flex",
										alignItems: "center",
										fontSize: "0.875rem",
									},
								},
							}}
							sx={{
								width: "100%",
								padding: 0,
								".MuiOutlinedInput-notchedOutline": changeColor(
									credentialsManagementInfo?.AccessTokenExpirationTxt,
									credentialsManagementInfo?.UserAccessTokenExpirationTxt
								) && {
									borderColor: "var(--data-con-blue)",
								},
							}}
						/>
					</Grid>
					<Grid item xs={12} sx={{ gap: 1 }}>
						<TextField
							label="Security Notes"
							variant="outlined"
							multiline
							rows={3}
							value={
								credentialsManagementInfo?.UserSecurityNotesTxt ||
								credentialsManagementInfo?.SecurityNotesTxt
							}
							onChange={(e) =>
								setCredentialsManagementInfo((prev) => ({
									...prev,
									UserSecurityNotesTxt: e.target.value,
								}))
							}
							onBlur={updateConnectorCredentialsManagement}
							InputLabelProps={{
								shrink: true,
								sx: {
									color:
										changeColor(
											credentialsManagementInfo?.SecurityNotesTxt,
											credentialsManagementInfo?.UserSecurityNotesTxt
										) && "var(--data-con-blue)",
								},
							}}
							InputProps={{
								sx: {
									padding: 1,
								},
								inputProps: {
									sx: {
										padding: 0,
										height: "100%",
										display: "flex",
										alignItems: "center",
										fontSize: "0.875rem",
									},
								},
							}}
							sx={{
								width: "100%",
								padding: 0,
								".MuiOutlinedInput-notchedOutline": changeColor(
									credentialsManagementInfo?.SecurityNotesTxt,
									credentialsManagementInfo?.UserSecurityNotesTxt
								) && {
									borderColor: "var(--data-con-blue)",
								},
							}}
						/>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default CredentialsManagement;
